import React from "react";
import { Carousel } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import ListingsPropertyCardCarousel from "./Listings-property-card-carousel";
import * as styles from "./featured-properties.module.scss";

const FeaturedProperties = ({
	properties,
	CardType,
	colWidthXL,
	colWidthLG,
}) => {
	return (
		<>
			<Container className="py-0 px-4 px-md-0">
				<Row className="g-4 pb-2 d-none d-md-flex">
					{properties?.map((property) => {
						return (
							<Col
								className="mb-5"
								md={6}
								lg={colWidthLG || 6}
								xl={colWidthXL || 6}
							>
								<CardType property={property} />
							</Col>
						);
					})}
				</Row>
			</Container>
			<Carousel
				variant="dark"
				className={`d-md-none mb-4 carousel-container`}
				nextIcon={
					<span
						aria-hidden="true"
						className={`carousel-control-next-icon ${styles.propertyCardNext}`}
					/>
				}
				prevIcon={
					<span
						aria-hidden="true"
						className={`carousel-control-prev-icon ${styles.propertyCardPrev}`}
					/>
				}
				controls={false}
				interval={null}
			>
				{properties?.map((property) => {
					return (
						<Carousel.Item>
							<div className="p-2 position">
								<ListingsPropertyCardCarousel property={property} />
							</div>
						</Carousel.Item>
					);
				})}
			</Carousel>
		</>
	);
};

const socialIcons = {
	color: "rgba(0, 0, 0, 0.5)",
};

const quickLinks = {};

export default FeaturedProperties;
