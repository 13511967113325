import React, { useState, useEffect, useRef } from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import parse from "html-react-parser";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Layout from "../components/layout";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";
import Navigation from "../components/navigation";
import { Button, Card } from "react-bootstrap";
import PreferToTalk from "../components/prefer-to-talk";
import CartContext from "../context/CartContext";
import VerticalListingsPropertyCard from "../components/vertical-listings-property-card";
import AnimatedImageCard from "../components/animated-image-card";
import ContactFormHome from "../components/contact-form-home";
import {
	FaBuilding,
	FaHeart,
	FaMapMarkerAlt,
	FaRegHeart,
} from "react-icons/fa";
import ImageSlider from "../components/image-slider";
import AmenitiesIcons from "../components/amenities-icons";
import { MdLocationPin, MdMonitor, MdOutlineClear } from "react-icons/md";
import PricingSeciton from "../components/pricing-section";
import gsap from "gsap";
import TubeDistance from "../components/tube-distance";
import FacilitiesBlock from "../components/facilities-block";
import MapComponent from "../components/map";
import StationsTags from "../components/stations-tags";
import FeaturedProperties from "../components/featured-properties";
import MapGL, { NavigationControl, Marker, Popup } from "@urbica/react-map-gl";
import MapSimpleCard from "../components/map-simple-card";
import MapSimpleCardNew from "../components/map-simple-card-new";
import MapCardModal from "../components/map-card-modal";
import MapCardModalNew from "../components/map-card-modal-new";
import ContactFormListings from "../components/contact-form-listings";
import FFPSection from "../components/features-for-page";

const slugify = require("slugify");
function round5(x) {
	return Math.ceil(x / 5) * 5;
}

const Listings = ({
	data: {
		property,
		site,
		properties: { edges: properties },
	},
}) => {
	//   const paddington = data.paddington?.localFile.childImageSharp.gatsbyImageData;

	//   const canaryWharf =
	//     data.canaryWharf?.localFile.childImageSharp.gatsbyImageData;
	//   const piccadilly = data.piccadilly?.localFile.childImageSharp.gatsbyImageData;
	//   const westminster =
	//     data.westminster?.localFile.childImageSharp.gatsbyImageData;

	const {
		subwayStation1,
		subwayStation1DistanceKm,
		subwayStation1DurationMins,
		subwayStation1Lines,
		subwayStation2,
		subwayStation2DistanceKm,
		subwayStation2DurationMins,
		subwayStation2Lines,
		subwayStation3,
		subwayStation3DistanceKm,
		subwayStation3DurationMins,
		subwayStation3Lines,
		airtableId,
		locationLatitude,
		locationLongitude,
		name: propertyName,
		services,
		virtualTourLink,
		amenities,
		type,
		desksFrom,
		rentPM,
		floorsAvailable,
		desksTo,
		features,
		description,
		addressLineOne,
		addressLineTwo,
		city,
		postCode,
		photosNew,
	} = property;

	const {
		cart,
		settingVal,
		selectedOfficeType,
		selectedFeatures,
		viewport,
		onLocationChange,
		numberOfPeople,
		OnAddToCart,
		OnRemoveFromCart,
		budgetPerDesk,
		onLocationChangeScroll,
		options,
		filteredOffices,
		newServiceOptions,
		newAmenitiesOptions,
		buttonChecked,
		newOptions,
		getDistanceFromLatLonInKm,
		distancePoint,
		distancePanPoint,
		onLocationChangePan,
		shortOffices,
		sortedBy,
	} = React.useContext(CartContext);

	const [activePopup, setActivePopup] = useState(-1);
	const [showPopup, setShowPopup] = useState(false);
	const [modalShow, setModalShow] = React.useState(false);
	const [modalShowDesktop, setModalShowDesktop] = React.useState(false);

	const upperCapacity = Math.min(desksTo, numberOfPeople?.max);
	const lowerCapacity = Math.max(desksFrom, numberOfPeople?.min);

	const onPinClick = (pin, active) => {
		// console.log("pin", pin, "activePopup", activePopup);

		setActivePopup(pin);

		setShowPopup(true);
	};
	var floors = [];

	useEffect(() => {
		if (property?.floorsAvailable !== null) {
			floors =
				property?.floorsAvailable
					?.sort((a, b) => parseFloat(a.rentPM) - parseFloat(b.rentPM))
					.slice() || [];
		}
	}, [property]);

	const onMouseExit = () => {
		gsap.to(".map-popup-div", { opacity: 0, duration: 0.3 });
		setTimeout(popupFalse, 400);
	};

	const popupFalse = () => {
		setShowPopup(false);
	};

	const mapRef = useRef();
	const siteUrl = site.siteMetadata.siteUrl;
	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Listings",
				item: {
					url: `${siteUrl}/listings`,
					id: `${siteUrl}/listings`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: `${propertyName}`,
				item: {
					url: `${siteUrl}/listings/${slugify(propertyName)}`,
					id: `${siteUrl}/listings/${slugify(propertyName)}`,
				},
			},
		],
	};
	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={`${propertyName} | Your Desk Workplace Solutions`}
				description={description?.replace(/â€™/g, "'")}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}`,
					title: `${propertyName} | Your Desk Workplace Solutions`,
					description: description?.replace(/â€™/g, "'"),
					images: [
						{
							url: `${photosNew[0]?.publicURL}`,
							width: `${photosNew[0]?.childImageSharp.original.width}`,
							height: `${photosNew[0]?.childImageSharp.original.height}`,
							alt: "Property main image",
						},
					],
				}}
			/>

			<section className="bg-primary">
				<Container fluid className="px-0 mx-0">
					{" "}
					<Row className="px-0 mx-0">
						<Col className="px-0 mx-0 d-none d-lg-block">
							<ImageSlider
								imageDataArr={photosNew}
								height="80vh"
								className=""
							/>
						</Col>
						<Col className="px-0 mx-0 d-lg-none">
							<ImageSlider
								imageDataArr={photosNew}
								height="50vh"
								className=" "
							/>
						</Col>
					</Row>
				</Container>
				<Container className="py-4">
					<Row>
						<Col md={6}>
							<div className="  pb-2">
								<h1 className="text-white text-center text-md-start ">
									{propertyName}
								</h1>
								<p className="text-white text-center text-md-start">
									<FaMapMarkerAlt />{" "}
									{`${addressLineOne ? `${addressLineOne}, ` : ""} ${
										addressLineTwo ? `${addressLineTwo}, ` : ""
									}${city ? `${city}, ` : ""}${postCode ? `${postCode}` : ""}`}
								</p>
							</div>

							<Row className="py-2">
								<Col
									lg={6}
									className=" mt-2 mt-md-0 text-center text-md-start "
								>
									{cart?.filter((e) => e.name === propertyName).length > 0 && (
										<div
											className="d-flex justify-content-center justify-content-md-start align-items-center"
											onClick={() => OnRemoveFromCart(property)}
											style={{ cursor: "pointer" }}
										>
											<FaHeart
												style={{ cursor: "pointer" }}
												className="me-2 fs-3 text-secondary"
											/>
											<p className="m-0 p-0 text-white fs-5">
												Remove from shortlist
											</p>
										</div>
									)}
									{cart?.filter((e) => e.name === propertyName).length < 1 && (
										<div
											className="d-flex justify-content-center justify-content-md-start align-items-center"
											onClick={() => OnAddToCart(property)}
											style={{ cursor: "pointer" }}
										>
											<FaRegHeart
												style={{ cursor: "pointer" }}
												className="me-2 fs-3 text-white"
											/>
											<p className="m-0 p-0 text-white fs-5">
												Add to shortlist
											</p>
										</div>
									)}
								</Col>

								{/* <Col
									lg={6}
									className="text-end d-flex justify-content-end d-lg-none pt-3"
								>
									<a href="#contact">Find out more</a>
								</Col> */}
							</Row>
						</Col>
						<Col className=" text-center text-md-end" md={6}>
							{type === "Serviced Office" && (
								<>
									<p className="mb-0 text-white Oswald-Regular fs-4">
										Private offices from
									</p>
									<h5 className="mt-1 text-white  fs-1">
										£{round5(rentPM).toLocaleString("en-UK")}pcm
									</h5>
								</>
							)}

							{type === "Managed Office" && (
								<>
									<p className="mb-0 text-white Oswald-Regular fs-4">
										Private floors from
									</p>
									<h5 className="mt-1 text-white  fs-1">
										£{round5(floors[0]?.rentPM).toLocaleString("en-GB")}pcm
									</h5>
								</>
							)}
							<p style={{ fontSize: "90%" }} className="text-white">
								*Subject to availability
							</p>
						</Col>
					</Row>
					<Row>
						<Col>
							<hr
								style={{ opacity: 1 }}
								className="bg-white text-white mt-2 mb-3"
							/>
						</Col>
					</Row>
					<Row>
						<Col>
							<FFPSection features={features} />
						</Col>
					</Row>
				</Container>
			</section>

			{description && (
				<section className="pt-5">
					<Container>
						<Row className="justify-content-center">
							<Col md={6} className="mb-4 mb-lg-0">
								<h2 className="fs-1 PB-4">Description</h2>
								<p className="mt-3" style={{ fontSize: "1.2rem" }}>
									{description?.replace(/â€™/g, "'")}
								</p>
							</Col>
							<Col md={6} lg={{ span: 4, offset: 2 }}>
								<div className="px-xl-6 mb-4">
									<PreferToTalk title="Ready to talk?" />
								</div>
							</Col>
						</Row>
					</Container>
				</section>
			)}
			<section>
				<Container>
					<Row
						className="d-none d-xl-flex align-items-center
					"
					>
						<Col xl={7}>
							<div className="">
								<MapGL
									ref={mapRef}
									style={{ width: "100%", height: "35rem" }}
									mapStyle="mapbox://styles/rjmdigital/clgnfpzan00dl01pcebkc629e"
									accessToken="pk.eyJ1IjoicmptZGlnaXRhbCIsImEiOiJjbGRibGk5d2MwMXJrM3ZsajRpMm93dXl1In0.OrnQu39yRgj7shF3hYX3xw"
									bounds={viewport?.bounds}
									latitude={locationLatitude}
									longitude={locationLongitude}
									zoom={viewport?.zoom}
									onViewportChange={(e) => onLocationChangePan(e)}
									viewportChangeMethod="flyTo"
								>
									<NavigationControl
										showZoom
										showCompass={false}
										position="top-right"
									/>
									{modalShowDesktop && (
										<div
											style={{ zIndex: 2 }}
											className="w-100 h-100 position-absolute start-0 top-0 "
										>
											<div
												onClick={() => setModalShowDesktop(false)}
												style={{ opacity: 0.45, zIndex: 2 }}
												className="w-100 h-100 start-0 top-0 bg-black
											"
											></div>
											<div
												style={{ zIndex: 4 }}
												className=" text-center position-absolute w-50 start-50 top-50 translate-middle"
											>
												<div
													style={{ zIndex: 5 }}
													className="bg-white h-100 w-100 px-0 mx-0 position-relative"
												>
													<div
														className="px-3 pt-2 position-absolute end-0 top-0"
														style={{ zIndex: 6 }}
													>
														{cart?.filter(
															(e) => e.airtableId === property?.airtableId
														).length > 0 && (
															<FaHeart
																style={{ cursor: "pointer", zIndex: 6 }}
																onClick={() => OnRemoveFromCart(property)}
																className="fs-4 text-dark-blue"
															/>
														)}
														{cart?.filter(
															(e) => e.airtableId === property?.airtableId
														).length < 1 && (
															<FaRegHeart
																style={{ cursor: "pointer", zIndex: 6 }}
																onClick={() => OnAddToCart(property)}
																className="fs-4 text-med-grey"
															/>
														)}
													</div>
													<div
														className="px-3 pt-2 position-absolute start-0 top-0"
														style={{ zIndex: 6 }}
													>
														<MdOutlineClear
															style={{ cursor: "pointer" }}
															className="fs-4"
															onClick={() => setModalShowDesktop(false)}
														/>
													</div>

													<div
														className="bg-white h-100 px-0 mx-0"
														onMouseEnter={() => {
															onLocationChangeScroll(
																property?.locationLatitude,
																property?.locationLongitude
															);
														}}
													>
														<Row
															className="px-0 mx-0 g-0  align-items-center"
															style={{
																borderBottom: "1px solid #f5f5f5",
															}}
														>
															<Col xs={12}>
																<ImageSlider
																	imageDataArr={property?.photosNew}
																	height="320px"
																/>
															</Col>
															<Col
																className="ps-4 px-lg-4 pt-3 py-2 text-black"
																style={{ minHeight: "100%" }}
																xs={12}
															>
																<h3 className="fs-4 pb-2">{property?.name}</h3>

																{property?.type === "Serviced Office" && (
																	<>
																		<p className="mb-0">Private offices from</p>
																		<h5 className="mt-1 Roboto-Medium fs-4">
																			£
																			{round5(property?.rentPM).toLocaleString(
																				"en-UK"
																			)}
																			pcm
																		</h5>
																	</>
																)}

																{property?.type === "Managed Office" && (
																	<>
																		<p className="mb-0">Private floors from</p>
																		<h5 className="mt-1 Roboto-Medium fs-4">
																			£
																			{round5(floors[0]?.rentPM).toLocaleString(
																				"en-GB"
																			)}
																			pcm
																		</h5>
																	</>
																)}
															</Col>
														</Row>
													</div>
												</div>
											</div>
										</div>
									)}
									return (
									<Marker
										latitude={locationLatitude}
										longitude={locationLongitude}
										// onClick={() => {
										// 	setproperty(property);
										// 	setModalShow(true);
										// }}
										onClick={() => {
											setModalShowDesktop(true);
										}}
									>
										<MdLocationPin
											className=" fs-2 text-primary"
											style={{
												maxWidth: "28px",
												cursor: "pointer",
											}}
										/>
									</Marker>
									);
								</MapGL>
							</div>
						</Col>
						<Col xl={{ span: 5 }}>
							<div className="ps-xl-6">
								<h2 className="fs-1 pb-4">Transport links</h2>
								<p className="text-black fs-5">
									<FaBuilding className="me-2" />{" "}
									{`${addressLineOne ? `${addressLineOne}, ` : ""} ${
										addressLineTwo ? `${addressLineTwo}, ` : ""
									}${city ? `${city}, ` : ""}${postCode ? `${postCode}` : ""}`}
								</p>
								<div className="p-0 m-0 pt-3 ps-2">
									{subwayStation1 !== null && (
										<TubeDistance
											station={subwayStation1}
											distanceKm={subwayStation1DistanceKm}
											walkTime={subwayStation1DurationMins}
											subwayLines={subwayStation1Lines}
											tube
										/>
									)}
									{subwayStation2 !== null && (
										<TubeDistance
											station={subwayStation2}
											distanceKm={subwayStation2DistanceKm}
											walkTime={subwayStation2DurationMins}
											subwayLines={subwayStation2Lines}
											tube
										/>
									)}
									{subwayStation3 !== null && (
										<TubeDistance
											station={subwayStation3}
											distanceKm={subwayStation3DistanceKm}
											walkTime={subwayStation3DurationMins}
											subwayLines={subwayStation3Lines}
											tube
										/>
									)}
								</div>
							</div>
						</Col>
					</Row>

					<Row className="d-xl-none">
						<Col xs={12}>
							{modalShow && (
								<MapCardModalNew
									show={modalShow}
									property={property}
									onHide={() => setModalShow(false)}
								/>
							)}
							<div className="pb-5 mt-4">
								<MapGL
									ref={mapRef}
									style={{ width: "100%", height: "70vh" }}
									mapStyle="mapbox://styles/rjmdigital/clgnfpzan00dl01pcebkc629e"
									accessToken="pk.eyJ1IjoicmptZGlnaXRhbCIsImEiOiJjbGRibGk5d2MwMXJrM3ZsajRpMm93dXl1In0.OrnQu39yRgj7shF3hYX3xw"
									bounds={viewport?.bounds}
									latitude={viewport?.latitude}
									longitude={viewport?.longitude}
									zoom={viewport?.zoom}
									onViewportChange={(e) => onLocationChangePan(e)}
									viewportChangeMethod="flyTo"
								>
									<NavigationControl
										showZoom
										showCompass={false}
										position="top-right"
									/>
									return (
									<Marker
										latitude={locationLatitude}
										longitude={locationLongitude}
										onClick={() => {
											setModalShow(true);
										}}
										// onClick={() => onPinClick(property)}
									>
										<MdLocationPin
											className=" fs-2 text-secondary"
											style={{
												maxWidth: "28px",
												cursor: "pointer",
											}}
										/>
									</Marker>
									);
								</MapGL>
							</div>

							{/* <MapGL

                //   ref={mapRef}
                style={{ width: "100%", height: "350px" }}
                mapStyle="mapbox://styles/mapbox/light-v9"
                accessToken="pk.eyJ1IjoicmptZGlnaXRhbCIsImEiOiJjbGRibGk5d2MwMXJrM3ZsajRpMm93dXl1In0.OrnQu39yRgj7shF3hYX3xw"
                bounds={viewport?.bounds}
                // latitude={viewport?.latitude}
                // longitude={viewport?.longitude}
                latitude={locationLatitude}
                longitude={locationLongitude}
                zoom={viewport?.zoom}
                onViewportChange={(e) => onLocationChangePan(e)}
                viewportChangeMethod="flyTo"
              >
                <GeolocateControl
                  onGeolocate={(e) => settingVal(e, "viewportValue")}
                  position="top-right"
                />

                <Marker
                  key={airtableId}
                  longitude={locationLongitude}
                  latitude={locationLatitude}
                  //   onClick={() => onPinClick(property)}
                >
                  <FaMapMarkerAlt
                    style={{ cursor: "pointer" }}
                    className={`fs-4  text-primary`}
                  />
                </Marker>

                {showPopup && (
                  <Popup
                    longitude={activePopup.node.locationLongitude}
                    latitude={activePopup.node.locationLatitude}
                    closeButton={false}
                    closeOnClick={false}
                  >
                    <div className=" position-relative w-100">
                      <Button
                        style={{ top: "0rem", right: "0rem" }}
                        onClick={() => setShowPopup(false)}
                        variant="secondary"
                        className=" position-absolute"
                      >
                        X
                      </Button>
                      <h2 className="fs-5">{activePopup.node.name}</h2>
                      <GatsbyImage
                        style={{ width: "200px", height: "150px" }}
                        image={
                          activePopup.node.featuredImageNew.childImageSharp
                            .gatsbyImageData
                        }
                        alt="Featured Image"
                      />
                    </div>
                  </Popup>
                )}
              </MapGL> */}
						</Col>
						<Col>
							<h2 className="fs-1 pb-4">Transport links</h2>
							<p className="text-black fs-5">
								<FaBuilding className="me-2" />{" "}
								{`${addressLineOne ? `${addressLineOne}, ` : ""} ${
									addressLineTwo ? `${addressLineTwo}, ` : ""
								}${city ? `${city}, ` : ""}${postCode ? `${postCode}` : ""}`}
							</p>
							<div className="p-0 m-0 pt-3 ps-2">
								{subwayStation1 !== null && (
									<TubeDistance
										station={subwayStation1}
										distanceKm={subwayStation1DistanceKm}
										walkTime={subwayStation1DurationMins}
										subwayLines={subwayStation1Lines}
										tube
									/>
								)}
								{subwayStation2 !== null && (
									<TubeDistance
										station={subwayStation2}
										distanceKm={subwayStation2DistanceKm}
										walkTime={subwayStation2DurationMins}
										subwayLines={subwayStation2Lines}
										tube
									/>
								)}
								{subwayStation3 !== null && (
									<TubeDistance
										station={subwayStation3}
										distanceKm={subwayStation3DistanceKm}
										walkTime={subwayStation3DurationMins}
										subwayLines={subwayStation3Lines}
										tube
									/>
								)}
							</div>
						</Col>
					</Row>
				</Container>
			</section>

			<section className="pt-5 pt-lg-7">
				<Container>
					<Row>
						<Col className="text-center">
							<h2 className="fs-1 pb-4">Other nearby office spaces</h2>
						</Col>
					</Row>

					<Row>
						<FeaturedProperties
							colWidthXL={4}
							colWidthLG={6}
							CardType={VerticalListingsPropertyCard}
							properties={shortOffices

								?.filter((office) => office.node.name !== propertyName)
								.slice(0, 3)}
						/>
					</Row>
					<Row>
						<Col className="text-end">
							<Button
								className="btn btn-darker-blue w-100 w-md-auto text-white py-3  px-5 "
								as={Link}
								to="/properties"
							>
								Search all properties
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			<section>
				<Container>
					<div className="py-3 py-md-5 px-3 px-md-5 my-lg-6 my-3 ">
						<Row>
							<Col>
								<h2 className="fs-1 pb-4">Get in touch</h2>
							</Col>
						</Row>
						<Row>
							<Col>
								<p className="pb-4">
									Fill in your contact details below and we'll put you in touch
									with one of our experts.
								</p>
							</Col>
						</Row>
						<ContactFormHome />
					</div>
				</Container>
			</section>
		</Layout>
	);
};

export default Listings;

export const pageQuery = graphql`
	query PropertyById($id: String!) {
		property: airtableProperties(id: { eq: $id }) {
			subwayStation1
			subwayStation1DistanceKm
			subwayStation1DurationMins
			subwayStation1Lines
			subwayStation2
			subwayStation2DistanceKm
			subwayStation2DurationMins
			subwayStation2Lines
			subwayStation3
			subwayStation3DistanceKm
			subwayStation3DurationMins
			subwayStation3Lines
			airtableId
			locationLatitude
			locationLongitude
			virtualTourLink
			name
			services
			amenities
			desksTo
			features
			description
			addressLineOne
			addressLineTwo
			city
			postCode
			type
			desksFrom
			rentPM
			floorsAvailable {
				desksTo
				desksFrom
				name
				rentPM
			}
			photosNew {
				publicURL
				childImageSharp {
					original {
						height
						width
					}
					gatsbyImageData(quality: 30, placeholder: BLURRED, formats: AUTO)
				}
			}
		}

		properties: allAirtableProperties(
			filter: { isFloor: { nin: 1 } }
			limit: 4
		) {
			edges {
				node {
					subwayStation1
					subwayStation1DistanceKm
					subwayStation1DurationMins
					subwayStation2
					subwayStation2DistanceKm
					subwayStation2DurationMins
					airtableId
					locationLatitude
					locationLongitude
					name
					services
					amenities
					rentPM
					desksTo
					features
					description
					addressLineOne
					photosNew {
						childImageSharp {
							gatsbyImageData(quality: 30, placeholder: BLURRED, formats: AUTO)
						}
					}
				}
			}
		}

		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
