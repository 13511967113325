import React from "react";
import { Link } from "gatsby";
import { Col, Row } from "react-bootstrap";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { MdOutlineClear } from "react-icons/md";
import ImageSlider from "./image-slider";
import CartContext from "../context/CartContext";
import slugify from "slugify";

const MapSimpleCardNew = ({ property, closePopup }) => {
	const {
		cart,
		onLocationChangeScroll,
		getDistanceFromLatLonInKm,
		OnAddToCart,
		OnRemoveFromCart,
		distancePoint,
		selectedLocation,
	} = React.useContext(CartContext);

	var floors = [];
	if (property?.floorsAvailable !== null) {
		floors =
			property?.floorsAvailable
				?.sort((a, b) => parseFloat(a.rentPM) - parseFloat(b.rentPM))
				.slice() || [];
	}

	return (
		<div className="bg-white h-100 p-4 m-0 whiteish position-relative rounded">
			<div
				className=" pb-1 end-0 top-0 d-flex justify-content-between"
				style={{ zIndex: 2 }}
			>
				<div className="py-0 my-0" style={{ zIndex: 3 }}>
					{/* <MdOutlineClear className="fs-4" /> */}
					<MdOutlineClear
						className="fs-5"
						onClick={closePopup}
						style={{ cursor: "pointer" }}
					/>
				</div>
				<div className="py-0 my-0" style={{ zIndex: 3 }}>
					{cart?.filter((e) => e.airtableId === property?.airtableId).length >
						0 && (
						<FaHeart
							style={{ cursor: "pointer" }}
							onClick={() => OnRemoveFromCart(property)}
							className="fs-6 text-dark-blue"
						/>
					)}
					{cart?.filter((e) => e.airtableId === property?.airtableId).length <
						1 && (
						<FaRegHeart
							style={{ cursor: "pointer" }}
							onClick={() => OnAddToCart(property)}
							className="fs-6 text-med-grey"
						/>
					)}
				</div>
			</div>

			<Link className="outline" to={`/listings/${slugify(property?.name)}`}>
				<div
					className="bg-white h-100 px-0 mx-0 outline"
					style={{ minWidth: "200px" }}
					onMouseEnter={() => {
						onLocationChangeScroll(
							property?.locationLatitude,
							property?.locationLongitude
						);
					}}
				>
					<Row
						className="p-0 mx-0 g-0 w-100 align-items-center outline"
						// style={{ borderBottom: "1px solid #f5f5f5" }}
					>
						<Col xs={12} className="w-100">
							<ImageSlider imageDataArr={property?.photosNew} height="160px" />
						</Col>
						<Col
							className="px-1 text-black"
							style={{ minHeight: "100%" }}
							xs={12}
						>
							<h3 className="fs-4 pt-2 pb-1">{property?.name}</h3>

							<blockquote className="mb-0">
								{property?.type === "Serviced Office" && (
									<>
										<p className="mb-1">Private offices from</p>
										<h5 className="mt-1">£{property?.rentPM}pcm</h5>
									</>
								)}
								{property?.type === "Managed Office" && (
									<>
										<p className="mb-1">Private floors from</p>
										<h5 className="mt-1">£{floors[0]?.rentPM}pcm</h5>
									</>
								)}
							</blockquote>
						</Col>
					</Row>
				</div>
			</Link>
		</div>
	);
};

export default MapSimpleCardNew;
