import React, { useState } from "react";

import MapGL, {
	NavigationControl,
	Marker,
	Popup,
} from "@urbica/react-map-gl";
import { FaMapMarkerAlt } from "react-icons/fa";
import CartContext from "../context/CartContext";
import MapSimpleCard from "./map-simple-card";
import MapCardModal from "./map-card-modal";

const MapComponent = ({
	locationLatitude,
	locationLongitude,
	airtableId,
	properties,
	height,
}) => {
	const { settingVal, viewport, onLocationChangePan } =
		React.useContext(CartContext);

	const [activePopup, setActivePopup] = useState(-1);
	const [showPopup, setShowPopup] = useState(false);
	const [modalShow, setModalShow] = React.useState(false);
	const [modalProperty, setModalProperty] = React.useState("");
	const onPinClick = (pin, active) => {
		// console.log("pin", pin, "activePopup", activePopup);

		setActivePopup(pin);

		setShowPopup(true);
	};

	const initialValueTest = {
		latStart: 0,
		latEnd: 0,
		longStart: 0,
		longEnd: 0,
	};

	const propertiesArray = properties
		? properties
		: [locationLatitude, locationLongitude, airtableId];

	const setBounds = (propertiesArr) => {
		if (propertiesArr.length === 1) return null;
		const boundsArray = propertiesArr.reduce((testBounds, prop, i, a) => {
			// console.log("prop", prop);
			const newBounds = { ...testBounds };
			const lat = prop.node?.locationLatitude || prop.locationLatitude;
			const long = prop.node?.locationLongitude || prop.locationLongitude;

			if (lat === null || long === null) {
				return newBounds;
			}

			if (testBounds.latEnd === 0 || lat > testBounds.latEnd) {
				//   console.log("lat higher");
				newBounds.latEnd = lat;
			}
			if (testBounds.latStart === 0 || lat < testBounds.latStart) {
				newBounds.latStart = lat;
			}
			if (testBounds.longEnd === 0 || long > testBounds.longEnd) {
				newBounds.longEnd = long;
			}
			if (testBounds.longStart === 0 || long < testBounds.longStart) {
				newBounds.longStart = long;
			}
			return newBounds;
		}, initialValueTest);

		return [
			[boundsArray.longStart - 0.001, boundsArray.latStart - 0.001],
			[boundsArray.longEnd + 0.001, boundsArray.latEnd + 0.001],
		];
	};

	const bounds = setBounds(propertiesArray);

	return (
		<>
			{modalShow && (
				<MapCardModal
					show={modalShow}
					property={modalProperty}
					onHide={() => setShowPopup(false)}
				/>
			)}
			<div className="d-none d-xl-block">
				<MapGL
					//   ref={mapRef}
					style={{ width: "100%", height: `${height || "350"}px` }}
					mapStyle="mapbox://styles/rjmdigital/clgnfpzan00dl01pcebkc629e"
					accessToken="pk.eyJ1IjoicmptZGlnaXRhbCIsImEiOiJjbGRibGk5d2MwMXJrM3ZsajRpMm93dXl1In0.OrnQu39yRgj7shF3hYX3xw"
					bounds={bounds || viewport?.bounds}
					// latitude={viewport?.latitude}
					// longitude={viewport?.longitude}
					latitude={locationLatitude}
					longitude={locationLongitude}
					zoom={viewport?.zoom}
					onViewportChange={(e) => onLocationChangePan(e)}
					viewportChangeMethod="flyTo"
					padding={{ left: "15", top: "15", right: "15", bottom: "15" }}
				>
					<NavigationControl
						showZoom
						showCompass={false}
						position="top-right"
					/>

					{/* <Marker
          key={airtableId}
          longitude={locationLongitude}
          latitude={locationLatitude}
          //   onClick={() => onPinClick(property)}
        >
          <FaMapMarkerAlt
            style={{ cursor: "pointer" }}
            className={`fs-4  text-primary`}
          />
        </Marker> */}

					{propertiesArray?.map((property, i) => (
						<Marker
							key={i}
							longitude={
								property.node?.locationLongitude || property.locationLongitude
							}
							latitude={
								property.node?.locationLatitude || property.locationLatitude
							}
							onClick={() => onPinClick(property)}
						>
							<FaMapMarkerAlt
								style={{ cursor: "pointer" }}
								className={`fs-4  ${
									viewport?.latitude === property.node?.locationLatitude ||
									(property.locationLatitude &&
										viewport?.longitude === property.node?.locationLongitude) ||
									property.locationLongitude
										? "text-secondary"
										: "text-primary"
								}`}
							/>
						</Marker>
					))}
					{showPopup && (
						<Popup
							longitude={activePopup.node?.locationLongitude}
							latitude={activePopup.node?.locationLatitude}
							closeButton={false}
							closeOnClick={false}
							anchor="center"
							className="p-0 "
						>
							<MapSimpleCard
								property={activePopup}
								closePopup={() => setShowPopup(false)}
							/>
						</Popup>
					)}
				</MapGL>
			</div>
			<div className="d-xl-none">
				<MapGL
					style={{ width: "100%", height: "70vh" }}
					mapStyle="mapbox://styles/rjmdigital/clgnfpzan00dl01pcebkc629e"
					accessToken="pk.eyJ1IjoicmptZGlnaXRhbCIsImEiOiJjbGRibGk5d2MwMXJrM3ZsajRpMm93dXl1In0.OrnQu39yRgj7shF3hYX3xw"
					bounds={viewport?.bounds}
					latitude={viewport?.latitude}
					longitude={viewport?.longitude}
					zoom={viewport?.zoom}
					onViewportChange={(e) => onLocationChangePan(e)}
					viewportChangeMethod="flyTo"
				>
					<NavigationControl
						showZoom
						showCompass={false}
						position="top-right"
					/>
					{propertiesArray?.map((property, i) => (
						<Marker
							key={i}
							longitude={
								property.node?.locationLongitude || property.locationLongitude
							}
							latitude={
								property.node?.locationLatitude || property.locationLatitude
							}
							onClick={() => {
								setModalProperty(property);
								setModalShow(true);
							}}
						>
							<FaMapMarkerAlt
								style={{ cursor: "pointer" }}
								className={`fs-4  ${
									viewport?.latitude === property.node?.locationLatitude ||
									(property.locationLatitude &&
										viewport?.longitude === property.node?.locationLongitude) ||
									property.locationLongitude
										? "text-secondary"
										: "text-primary"
								}`}
							/>
						</Marker>
					))}
				</MapGL>
			</div>
			{/* <Row>
							<Col className="text-center mt-5">
							  <Button
								style={{ borderRadius: "200px" }}
								onClick={() => setOfficeAmount(officeAmount + 5)}
								variant="dark-blue w-100 w-md-auto px-4"
							  >
								LOAD MORE
							  </Button>
							</Col>
						  </Row> */}
		</>
	);
};

export default MapComponent;
