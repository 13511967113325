import React from "react";
import { Col, Row } from "react-bootstrap";
import PricingSecitonOtherOptionsText from "./pricing-section-other-options-text";
import PricingSecitonText from "./pricing-section-text";

const PricingSeciton = ({ property, otherOptions }) => {
	const {
		desksTo,
		desksFrom,
		rentPM,
		name,
		floorsAvailable: floors,
	} = property;
	function round5(x) {
		return Math.ceil(x / 5) * 5;
	}
	const otherOptionsMarkup = (
		<>
			<h3>Other available options near {name}</h3>

			<Row className="mt-4">
				{otherOptions?.map((option) => {
					return (
						<PricingSecitonOtherOptionsText
							type={option.node.type}
							rentPM={round5(option.node.rentPM)}
						/>
					);
				})}
			</Row>
		</>
	);

	const markup = floors ? (
		<Row>
			{floors.map((floor) => {
				return (
					<Col xl={6}>
						<PricingSecitonText
							desksTo={floor.desksTo}
							desksFrom={floor.desksFrom}
							rentPM={round5(floor.rentPM)}
							name={floor.name}
							floors
						/>
						<br />
					</Col>
				);
			})}
		</Row>
	) : (
		<>
			<PricingSecitonText
				desksTo={desksTo}
				desksFrom={desksFrom}
				rentPM={round5(rentPM)}
				name={name}
			/>
		</>
	);

	return (
		<>
			<h2 className="fs-1 text-center text-lg-start my-4">Pricing</h2>
			{markup}
			<p>*Subject to availability</p>
		</>
	);
};

export default PricingSeciton;
