import React from "react";
import AmenitiesIcons from "./amenities-icons";

const FFPSection = ({ features, oneLine }) => {
	return (
		<>
			<div
				className={`text-med-grey ${
					oneLine ? "pill-tag-one-line" : "pill-tag"
				} text-center`}
			>
				{features
					?.filter((e, index) => index < 11)
					.map((feature, i) => (
						<div
							className="d-inline-block text-white fw-light  pe-2 me-1 mb-3 "
							style={{ fontSize: "90%" }}
						>
							<div className="d-block px-3 ">
								<div className="fs-3">
									<AmenitiesIcons lookupValue={feature} />
								</div>
								<p className="d-block">{feature}</p>
							</div>
						</div>
					))}
			</div>
		</>
	);
};

export default FFPSection;
