import { Link, useStaticQuery, graphql } from "gatsby";
import React, { useState, useEffect } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import NavDropdown from "react-bootstrap/NavDropdown";
import { FaHeart, FaPhone, FaRegHeart } from "react-icons/fa";
import { RxHamburgerMenu } from "react-icons/rx";
import { GatsbyImage } from "gatsby-plugin-image";
import { Container } from "react-bootstrap";
import gsap from "gsap";
import CartContext from "../context/CartContext";
import ShortlistWidget from "./shortlist-widget";

// import { staticPopLocs } from "../utils/utils";

const Navigation = ({ homepage, shortlistPage }) => {
	// const data = useStaticQuery(graphql`
	// 	query {
	// 		whiteLogo: wpMediaItem(title: { eq: "Flex-Master-Logo-White" }) {
	// 			altText
	// 			localFile {
	// 				childImageSharp {
	// 					gatsbyImageData(
	// 						formats: [AUTO, WEBP]
	// 						quality: 100
	// 						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
	// 						layout: CONSTRAINED
	// 						placeholder: BLURRED
	// 					)
	// 				}
	// 			}
	// 		}
	// 		colorLogo: wpMediaItem(title: { eq: "Flex-MasterLogo-For-White-Bg" }) {
	// 			altText
	// 			localFile {
	// 				childImageSharp {
	// 					gatsbyImageData(
	// 						formats: [AUTO, WEBP]
	// 						quality: 100
	// 						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
	// 						layout: CONSTRAINED
	// 						placeholder: BLURRED
	// 					)
	// 				}
	// 			}
	// 		}
	// 		areaGuides: allWpAreaGuide {
	// 			edges {
	// 				node {
	// 					slug
	// 					title
	// 					id
	// 				}
	// 			}
	// 		}
	// 	}
	// `);

	const [Expanded, setExpanded] = useState(false);
	const [buttonBorder, setButtonBorder] = useState(false);

	const handleMouseLeave = () => {
		setExpanded(false);
		setButtonBorder(false);

		gsap.to(".navbar-bottom", { scaleY: 0, duration: 0.5, delay: 0.5 });

		gsap.to(`.nav-bg`, {
			opacity: 0,
			duration: 0.1,
			delay: 1,
		});
	};

	const handleOtherClick = () => {
		setExpanded(false);
		gsap.to(
			".navbar-bottom",

			{ scaleY: 0, duration: 0.5, delay: 0.5 }
		);
	};

	const handleClick = () => {
		if (Expanded === true) {
			setExpanded(false);
			setButtonBorder(false);
			gsap.to(".navbar-bottom", { scaleY: 0, duration: 0.5, delay: 0.5 });
			gsap.to(`.nav-bg`, {
				opacity: 0,
				duration: 0.1,
				delay: 1,
			});
		} else if (Expanded === false) {
			setExpanded(true);
			setButtonBorder(true);
			gsap.to(`.nav-bg`, {
				opacity: 1,
				duration: 0.1,
				delay: 0.1,
			});
			gsap.fromTo(
				".navbar-bottom",

				{ scaleY: 0, duration: 0.5 },
				{ scaleY: 1, duration: 0.5, delay: 0.2 }
			);
		}
	};

	// useEffect(() => {
	//   if (Expanded === true) {
	//     gsap.to(".dropdown-content", {
	//       opacity: 1,
	//       duration: 0.5,
	//       delay: 0.5,
	//     });
	//   }
	// }, [selectedDropdown]);

	useEffect(() => {
		if (Expanded === false) {
			gsap.to(".dropdown-content", {
				opacity: 0,
				duration: 0.5,
			});
		}
		if (Expanded === true) {
			gsap.to(".dropdown-content", {
				opacity: 1,
				duration: 0.5,
				delay: 0.5,
			});
		}
	}, [Expanded]);

	useEffect(() => {
		gsap.to(".fade-in-cart", {
			opacity: 1,
			duration: 0.5,
			delay: 0.4,
		});
	}, []);

	// const whiteLogo = data.whiteLogo?.localFile.childImageSharp.gatsbyImageData;
	// const colorLogo = data.colorLogo?.localFile.childImageSharp.gatsbyImageData;
	const textColor = homepage ? "white" : "dark-blue";
	const heartTextColor = homepage ? "black" : "white";
	// const logo = homepage ? whiteLogo : colorLogo;
	// const bgColor = homepage ? "primary" : "white";
	const bgColor = homepage ? "" : "white";
	const dropdownGradient = homepage
		? "dropdown-gradient-homepage"
		: "dropdown-gradient";
	// const sideColor = homepage ? "dark-blue" : "white";
	const navPosition = homepage ? "nav-position" : "";
	//   const [navPosition, setNavPosition] = useState(
	//     homepage ? "w-100 position-absolute start-50 translate-middle-x bg-t" : ""
	//   );

	const { cart, settingVal, onLocationChange } = React.useContext(CartContext);

	// The forwardRef is important!!
	// Dropdown needs access to the DOM node in order to position the Menu
	const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
		<a
			href=""
			ref={ref}
			onClick={(e) => {
				e.preventDefault();
				onClick(e);
			}}
		>
			{children}
			&#x25bc;
		</a>
	));

	// const fetchGeocode = async (locName) => {
	//   const accessToken =
	//     "pk.eyJ1IjoicmptZGlnaXRhbCIsImEiOiJjbGRibGk5d2MwMXJrM3ZsajRpMm93dXl1In0.OrnQu39yRgj7shF3hYX3xw";
	//   const endpoint = "mapbox.places";
	//   const url = `https://api.mapbox.com/geocoding/v5/${endpoint}/${locName}.json?access_token=${accessToken}`;
	//   const response = await fetch(url);
	//   const data = await response.json();
	//   return await data.features[0].center;
	// };

	// const lat = async (locName) => {
	//   const locLat = await fetchGeocode(locName);
	//   return locLat[1];
	// };
	// const long = async (locName) => {
	//   const locLong = await fetchGeocode(locName);
	//   return locLong[0];
	// };

	const staticPopLocs = [
		{
			shortLocationName: "Bank",
			locationName: "Bank, London, UK",
			lat: 51.51757,
			long: -0.120609,
		},

		{
			shortLocationName: "Canary Wharf and Docklands",
			locationName: "Canary Wharf and Docklands, London, UK",
			lat: 51.506156,
			long: -0.021452,
		},

		{
			shortLocationName: "Clerkenwell",
			locationName: "Clerkenwell, London, UK",
			lat: 51.523727,
			long: -0.105556,
		},

		{
			shortLocationName: "Covent Garden",
			locationName: "Covent Garden, London, UK",
			lat: 51.511736,
			long: -0.122677,
		},

		{
			shortLocationName: "Kings Cross",
			locationName: "Kings Cross, London, UK",
			lat: 51.529108,
			long: -0.122413,
		},

		{
			shortLocationName: "Knightsbridge",
			locationName: "Knightsbridge, London, UK",
			lat: 51.500844,
			long: -0.166965,
		},

		{
			shortLocationName: "London Bridge / Borough",
			locationName: "London Bridge / Borough, London, UK",
			lat: 51.496614,
			long: -0.0162935,
		},

		{
			shortLocationName: "Marylebone",
			locationName: "Marylebone, London, UK",
			lat: 51.522059,
			long: -0.150455,
		},

		{
			shortLocationName: "Mayfair",
			locationName: "Mayfair, London, UK",
			lat: 51.511087,
			long: -0.147058,
		},

		{
			shortLocationName: "Midtown",
			locationName: "Midtown, London, UK",
			lat: 51.507322,
			long: -0.127647,
		},

		{
			shortLocationName: "Paddington",
			locationName: "Paddington, London, UK",
			lat: 51.516305,
			long: -0.179242,
		},

		{
			shortLocationName: "Shoreditch",
			locationName: "Shoreditch, London, UK",
			lat: 51.526669,
			long: -0.079893,
		},

		{
			shortLocationName: "Soho",
			locationName: "Soho, London, UK",
			lat: 51.513163,
			long: -0.131175,
		},

		{
			shortLocationName: "St Pauls",
			locationName: "St Pauls, London, UK",
			lat: 51.513962,
			long: -0.10114,
		},

		{
			shortLocationName: "Victoria",
			locationName: "Victoria, London, UK",
			lat: 51.495979,
			long: -0.141317,
		},
	];

	const OnPopLocLinkClick = (loc) => {
		settingVal(loc.locationName, "locationValue");
		onLocationChange(loc.lat, loc.long);
		handleClick();
	};

	return (
		<>
			<div style={{ opacity: 0 }} className="fade-in-cart ">
				{cart !== null &&
					cart !== "undefined" &&
					cart?.length > 0 &&
					shortlistPage !== true && (
						<div
							style={{
								zIndex: 999999,
								width: "400px",
							}}
							className="position-fixed end-0 bottom-0"
						>
							<ShortlistWidget />
							{/* <Accordion className="w-100" defaultActiveKey="-1">

							<Accordion.Item eventKey="0">
								<Accordion.Header>Shortlist</Accordion.Header>
								<Accordion.Body
									style={{ overflowY: "auto", maxHeight: "500px" }}
								>
									{cart.map((property, i) => (
										<ShortlistWidgetCard property={property} />
									))}
								</Accordion.Body>
							</Accordion.Item>
						</Accordion> */}
						</div>
					)}
			</div>

			<div className=" p-0" onMouseLeave={() => handleMouseLeave()}>
				<div className={navPosition}>
					<Navbar
						bg={bgColor}
						variant={textColor}
						expand="xl"
						className={`py-4 py-lg-5 position-relative`}
						style={{ zIndex: 1 }}
					>
						<div
							style={{ zIndex: -999 }}
							className={`position-absolute ${
								homepage ? "nav-bg bg-primary" : ""
							}  w-100 h-100`}
						></div>
						<Container className={` bg=${bgColor} py-0 px-3 px-xl-0`}>
							<Navbar.Brand
								style={{ maxWidth: "200px" }}
								href="/"
								className=" me-lg-6 "
							>
								{/* <GatsbyImage image={logo} alt={data.logo?.altText} /> */}
							</Navbar.Brand>
							<Navbar.Toggle
								aria-controls="basic-navbar-nav"
								className={`border-${textColor}`}
							>
								<RxHamburgerMenu
									className={`text-${textColor} border-${textColor}`}
								/>
							</Navbar.Toggle>
							{/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
							<Navbar.Collapse id="basic-navbar-nav" className="pt-3">
								<Nav className="w-100 d-flex justify-content-end">
									{/* <Nav.Link

                    as={Link}
                    to="/properties"
                    className={`text-${textColor} fs-5`}
                  >
                    Locations
                  </Nav.Link> */}
									<Nav.Link
										className={`text-${textColor} fs-5 d-none me-xl-5 d-xl-inline-block`}
										onClick={() => handleClick()}
									>
										Popular Locations
									</Nav.Link>

									<NavDropdown
										className={`text-${textColor} fs-5 d-xl-none `}
										title={
											<span className={`text-${textColor} fs-5`}>
												Popular Locations
											</span>
										}
										id="collasible-nav-dropdown"
										style={{ backgroundColor: "rgba(0, 0, 0, 0.0)" }}
									>
										{staticPopLocs.map((loc) => {
											return (
												<NavDropdown.Item
													onClick={() =>
														settingVal(loc.shortLocationName, "idLocation")
													}
													className={`text-${textColor} fs-6`}
													as={Link}
													to={`/properties?location=${loc.shortLocationName}`}
												>
													{loc.shortLocationName}
												</NavDropdown.Item>
											);
										})}
									</NavDropdown>
									<Nav.Link
										as={Link}
										to="/contact-us"
										className={`text-${textColor} fs-5 me-xl-5`}
									>
										Contact Asset
									</Nav.Link>

									<Nav.Link
										href="tel:0207 123 895"
										className={`text-${textColor} fs-5 me-xl-5`}
									>
										<span className="d-flex align-items-center p-0">
											<FaPhone className="m-0 me-2 fs-6" />
											01323 385 385
										</span>
									</Nav.Link>
									<Nav.Link as={Link} to="/shortlist" className="ps-6 ps-xl-0">
										{cart !== null &&
											cart !== "undefined" &&
											cart?.length > 0 > 0 && (
												<div
													id="heart-div"
													className="position-relative m-0 p-0 d-inline me-xl-5"
												>
													<div className="position-absolute top-50 start-50 translate-middle">
														<p
															className={`my-0 fs-6 pt-1 fw-normal pt-0 text-${heartTextColor}`}
														>
															{cart?.length}
														</p>
													</div>
													<FaHeart
														className={`text-${textColor} m-0 p-0 fs-1 `}
													/>
												</div>
											)}
										{cart !== null ||
											cart !== "undefined" ||
											(cart?.length < 1 && (
												<FaRegHeart className={`text-${textColor} mt-0 fs-2`} />
											))}
									</Nav.Link>
									<Nav.Link as={Link} to="/contact-us" className="">
										<button
											className={`text-white btn btn-primary ${
												buttonBorder && homepage && "border-pale-grey"
											} px-4 rounded-pill w-100 w-md-50 w-lg-auto`}
										>
											GET A QUOTE
										</button>
									</Nav.Link>
								</Nav>
							</Navbar.Collapse>
						</Container>
					</Navbar>
				</div>

				<div className={`bg-${bgColor} w-100 position-relative`}>
					<div
						style={{ overflow: "hidden", zIndex: 4 }}
						className={`w-100 d-block position-absolute navbar-bottom ${dropdownGradient}`}
					>
						<Container className={` my-0`}>
							<Row className="px-6 pb-4 dropdown-content">
								{staticPopLocs.map((loc) => {
									return (
										<Col lg={6} className="py-2">
											<Link
												onClick={() =>
													settingVal(loc.shortLocationName, "idLocation")
												}
												to={`/properties?location=${loc.shortLocationName}`}
												className={`text-${textColor} my-0 fs-5 fw-bold`}
											>
												{loc.shortLocationName}
											</Link>
										</Col>
									);
								})}
							</Row>
						</Container>
					</div>
				</div>
			</div>
		</>
	);
};

export default Navigation;
